
//download store link

//function getMobileOperatingSystem() {
//  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
//  if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
//  {
//     return 'iOS';
//  }
//  else if( userAgent.match( /Android/i ) )
//  {
//     return 'Android';
//  }
//  else
//  {
//     return 'unknown';
//  }
//}
//function onLoad(){

//  //var chrome = navigator.userAgent.toLowerCase().indexOf("chrome");
//  //var sapari = navigator.userAgent.toLowerCase().indexOf("safari");
//  //let onlySafari = (chrome != -1 && sapari != -1) ? false : true;

//  let urlLink;

//  // 오직 아이폰 / 패드로만!! sapari X
//  const isIOSPlatform = /iPhone|iPad|iPod/i.test(navigator.platform)
//  // 오직 아이폰 아닌 다른 기기(pc X)로만
//  const otherPlatform = /Linux/i.test(navigator.platform)


//  let nh = window.innerHeight;

//  if (document.querySelector('.main-text')) {
//    document.querySelector('.main-text').style.height = nh + 'px';
//  }
//  urlLink = "https://play.google.com/store/apps/details?id=kr.dif.ifood.ifood";
//  if(getMobileOperatingSystem() == 'Android') {
//    //urlLink = "https://play.google.com/store/apps/details?id=kr.dif.ifood.ifood";
 
//  } else {
//    if (isIOSPlatform === true) {
//      urlLink = "https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%ED%91%B8%EB%93%9C-ifood/id6443722437";
//    } else if (otherPlatform === true) {
//      urlLink = "https://play.google.com/store/apps/details?id=kr.dif.ifood.ifood";
//      if (document.querySelector('.main-text')) {
//        document.querySelector('.main-text').style.height = nh + 'px';
//      }
//    } else {
//      if (document.querySelector('.main-text')) {
//        document.querySelector('.main-text').style.height = nh + 'px';
//      }
//    }
//  }

// let linkBtn = document.querySelectorAll('.iFoodApp');
//  for(let i=0; i<linkBtn.length; i++) {
//    linkBtn[i].setAttribute('href', urlLink);
//  }
//}
//onLoad();
//window.onload = onLoad;